import React from 'react';
import cx from 'classnames';
import './layout.css';

const Layout = ({ children, isEco = false }) => {
  return (
    <>
      <main className={cx({ 'bg-ecologyNavy': isEco })}>{children}</main>
    </>
  );
};

export default Layout;
